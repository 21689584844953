// Fonts
import '../icons/fonts/icons.svg';
import '../icons/fonts/icons.ttf';
import '../icons/fonts/icons.woff';

// Vendor – baguettebox.js (lightbox)
import '../../node_modules/baguettebox.js/src/baguetteBox.scss';
import baguetteBox from '../../node_modules/baguettebox.js/src/baguetteBox.js';

// Vendor – tiny-slider (slider)
import '../../node_modules/tiny-slider/src/tiny-slider.scss';
import { tns } from '../../node_modules/tiny-slider/src/tiny-slider.module';

// SCSS
import '../styles/app.scss';

document.addEventListener('DOMContentLoaded', () => {
    // Main menu
    Array.prototype.slice.call(document.querySelectorAll('[data-menu-trigger]')).forEach(trigger => {
        trigger.addEventListener('click', e => {
            e.preventDefault();
            document.body.classList.toggle('menu-active');
        });
    });

    const searchTrigger = document.querySelector('[data-search-trigger]');
    const searchContainer = document.querySelector('[data-search-container]');

    // Search trigger
    if (searchTrigger && searchContainer) {
        searchTrigger.addEventListener('click', e => {
            e.preventDefault();
            document.body.classList.add('search-active');
            searchContainer.querySelector('input[type="text"]').focus();
        });

        // Close the search container if the click was outside it
        document.addEventListener('click', event => {
            if (document.body.classList.contains('search-active') && !searchContainer.contains(event.target) && !searchTrigger.contains(event.target)) {
                document.body.classList.remove('search-active');
            }
        });
    }

    // Navigation
    Array.prototype.slice.call(document.querySelector('[data-navigation]').querySelectorAll('a.submenu, strong.submenu')).forEach(link => {
        // Expand the subnavigation if it is a trail
        if (link.classList.contains('trail') || link.nextElementSibling.querySelector('.active')) {
            link.classList.add('submenu-expand');
        }

        link.addEventListener('click', e => {
            e.preventDefault();
            link.classList.toggle('submenu-expand');
        });
    });

    // Galleries – slider
    Array.prototype.slice.call(document.querySelectorAll('[data-gallery]')).forEach(gallery => {
        tns({
            container: gallery,
            items: 1,
            nav: false,
            loop: false,
            autoHeight: true,
        });
    });

    // Galleries – lightbox
    baguetteBox.run('[data-gallery]', {
        captions: link => {
            const title = link.parentElement.querySelector('.gallery__title');
            const text = link.parentElement.querySelector('.gallery__text');
            let caption = [];

            if (title) {
                caption.push(title.innerText);
            }

            if (text) {
                caption.push(text.innerText);
            }

            return caption.join(' – ');
        }
    });

    // Item likes action
    (() => {
        const fetchLikes = [];

        Array.prototype.slice.call(document.querySelectorAll('[data-item-like]')).forEach(link => {
            const config = JSON.parse(link.dataset.itemLike);
            const storageKey = `${config.type}_likes`;
            const storage = JSON.parse(window.localStorage.getItem(storageKey)) || [];
            const counter = link.querySelector('[data-item-like-counter]');

            // Use the fill icon if like was given
            if (storage.indexOf(config.id) !== -1) {
                link.classList.add('active');
            }

            // Add the config to fetch likes
            fetchLikes.push({ id: config.id, type: config.type, counter });

            // Update the counter on click event
            link.addEventListener('click', e => {
                e.preventDefault();

                const storage = JSON.parse(window.localStorage.getItem(storageKey)) || [];
                const exists = storage.indexOf(config.id) !== -1;
                const request = new XMLHttpRequest();

                request.open(exists ? 'DELETE' : 'POST', config.url, true);
                request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
                request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
                request.onload = () => {
                    if (request.status === 200) {
                        counter.innerText = request.response;

                        if (exists) {
                            link.classList.remove('active');
                            storage.splice(storage.indexOf(config.id), 1);
                        } else {
                            link.classList.add('active');
                            storage.push(config.id);
                        }

                        window.localStorage.setItem(storageKey, JSON.stringify(storage));
                    }
                };

                request.send(`id=${config.id}&type=${config.type}&REQUEST_TOKEN=${config.rt}`);
            });
        });

        // Fetch the likes via ajax and update the counters
        if (fetchLikes.length > 0) {
            const request = new XMLHttpRequest();
            const query = fetchLikes.reduce((accumulator, current) => {
                return `${accumulator}${accumulator.length ? '&' : ''}entries[${current.type}][]=${current.id}`;
            }, '');

            request.open('GET', `${window.likes_counter_url}?${query}`, true);
            request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            request.onload = function() {
                if (request.status === 200) {
                    const response = JSON.parse(request.responseText);

                    fetchLikes.forEach(entry => {
                        entry.counter.innerText = (response[entry.type] && response[entry.type][entry.id]) ? response[entry.type][entry.id] : 0;
                    });
                }
            };

            request.send();
        }
    })();

    // Item social actions
    (() => {
        let currentItemActionsTrigger;
        let currentItemActionsPopup;

        const closePopup = () => {
            let popup = currentItemActionsPopup;

            currentItemActionsPopup.classList.remove('popup-active');
            currentItemActionsPopup = null;
            document.removeEventListener('click', itemActionsListener);

            return popup;
        };

        const itemActionsListener = event => {
            // Close the popup if the click was outside it
            if (currentItemActionsPopup && !currentItemActionsTrigger.contains(event.target) && !currentItemActionsPopup.contains(event.target)) {
                closePopup();
            }
        };

        // Initialize the triggers
        Array.prototype.slice.call(document.querySelectorAll('[data-item-social-actions]')).forEach(trigger => {
            trigger.addEventListener('click', e => {
                e.preventDefault();

                currentItemActionsTrigger = e.currentTarget;

                // Close the existing popup and stop further logic if the current popup belongs to the clicked trigger
                if (currentItemActionsPopup && closePopup() === e.currentTarget.nextElementSibling) {
                    return;
                }

                // Open the popup
                currentItemActionsPopup = e.currentTarget.nextElementSibling;
                currentItemActionsPopup.classList.add('popup-active');
                document.addEventListener('click', itemActionsListener);
            });
        });
    })();

    function updateCustomSelect(select, value) {
        value.innerText = select.querySelector(`option[value="${select.value}"]`).innerText;
    }

    // Select inputs
    Array.prototype.slice.call(document.querySelectorAll('select[data-custom-select]')).forEach(select => {
        const wrapper = document.createElement('div');
        wrapper.className = 'custom-select-wrapper';

        const value = document.createElement('div');
        value.className = 'custom-select-value';

        select.parentElement.appendChild(wrapper);
        wrapper.appendChild(select);
        wrapper.appendChild(value);

        select.addEventListener('change', () => updateCustomSelect(select, value));
        updateCustomSelect(select, value);
    });
});

if ('onbeforeinstallprompt' in window) {
    import(/* webpackChunkName: "installprompt" */'./_installprompt')
}
